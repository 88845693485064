'use strict'

function nanohtmlRawBrowser (tag) {
  var el = document.createElement('div')
  el.innerHTML = tag
  return toArray(el.childNodes)
}

function toArray (arr) {
  return Array.isArray(arr) ? arr : [].slice.call(arr)
}

module.exports = nanohtmlRawBrowser
