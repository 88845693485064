var html = require('choo/html')

module.exports = function () {
  return html`
    <svg class="Goal-glyph" viewBox="0 0 1000 344">
      <g fill="currentColor" fill-rule="evenodd">
        <path d="M778.72 35.68C737.94 3.48 689.02 0 669.9 0c-10.1 0-19.43.9-26.26 2.53l-2.7.62c-25.82 6.1-69.05 16.3-119.38 78.77l-1.47 1.83c-.84 1.2-1.27 2.46-1.27 3.78 0 1.14.28 2.24.88 3.3l1.15 1.9c8.3 13.88 15.22 25.95 20.7 36.2 1.97 3.8 7.22 5.04 10.77 1.5.34-.46.7-.94.94-1.32C596.73 69 630.8 61 653.34 55.7l2.8-.66c3.1-.75 8.13-1.18 13.8-1.18 10.48 0 46.9 1.75 75.33 24.2 25.28 19.95 38.1 51.82 38.1 94.74 0 71.9-34.8 99.96-64 110.84-11.12 4.16-23.13 6.27-35.66 6.27-30.06 0-53.2-12.04-53.5-12.2l-2.1-1.02c-25.1-10.9-62.6-33.63-98.04-104.8C457.4 25.92 396.36 12.1 355.94 2.93l-2.13-.47C350.73 1.77 342 .13 327.77.13c-23.4 0-68.42 4.6-107 35.4C194.4 56.55 163 97.37 163 172.48c0 45.77 9.42 76 33.6 107.8 4.53 6.2 46.65 60.58 127.5 60.58 11.97 0 24.4-1.22 36.9-3.62 14.77-2.83 37.56-10.12 63-28.78l21.25 19.17c2.37 2.17 6.5 1.94 8.62-.4 1.04-1.1 1.6-2.58 1.54-4.04l16.45-101.3v-1.17c0-1.73-.77-3.37-1.97-4.4-1.08-1.04-2.47-1.58-4.26-1.58h-.03L366.96 242l-1.87.3c-1.25.28-2.34.92-3.14 1.83-1.1 1.23-1.7 2.8-1.6 4.46.1 1.63.82 3.14 2.02 4.2l20.43 18.48c-10.73 6.58-21.46 10.94-31.9 12.97-8.9 1.7-17.7 2.56-26.16 2.56-55.87 0-83.36-36.67-84.5-38.22l-.55-.76c-17-22.32-22.75-41.33-22.75-75.34 0-43.05 12.6-74.95 37.45-94.8 27.66-22.06 63.24-23.77 73.5-23.77 5.6 0 10.7.43 13.96 1.18l2.18.5c32.27 7.3 76.45 17.3 137.7 140.33 32.35 64.96 72.92 107.45 123.92 129.85 5.93 3.03 37.55 18.1 78.1 18.1 18.92 0 37.25-3.24 54.5-9.65 29.78-11.12 99.07-49.1 99.07-161.42 0-75.06-31.87-115.98-58.6-137.1"
        />
      </g>
    </svg>
  `
}
