var html = require('choo/html')

module.exports = function () {
  return html`
    <svg class="Goal-glyph" viewBox="0 0 1000 509">
      <g fill="currentColor" fill-rule="evenodd">
        <path d="M694.8 121L593 217.7c-3.7 3.8-5.7 6.6-5.7 14.5v271c0 3.2 2.6 5.8 5.8 5.8h105c3.6 0 6-2.7 6-5.8V123.4c0-3.2-2.3-9-9-2.4M402 247l-102 96.5c-3.8 3.8-5.8 6.6-5.8 14.5v145.2c0 3 2.6 5.7 5.8 5.7h105c3.2 0 5.8-3 5.8-6V249.3c0-3.2-2.5-9-9-2.5"/>
        <path d="M741 3.7c0-.8-.3-1.6-1-2.2-.4-.5-1-.8-1.8-.8h-1.4L659 18.4h-1.4c-.6.2-1 .5-1.5 1-1 1-1 3 0 4l1 1L675 42 498.4 218.6 417 137c-1-1-2.5-1-3.4 0l-22 22L261 289.7c-1 1-1 2.4 0 3.3l22 22c1 1 2.3 1 3.2 0l129-128.8 59 59c0 .2 0 .3.2.4l22 22c1 1 2.5 1 3.4 0l200-200L719 86c1 1 3 1 4 0 .7-.6 1-1.3 1-2l17-79.4v-1"/>
        <path d="M549 264l-49.3 49.5c-1 .8-2.3.7-3 0L452 267.8c-8.2-8.2-10-1.6-10 1.6V503c0 3.2 2.7 5.8 6 5.8h105c3 0 5.7-2.6 5.7-5.8V265.8c0-3.3-2.6-9-9.8-2"/>
      </g>
    </svg>
  `
}
