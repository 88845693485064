var html = require('choo/html')

module.exports = function () {
  return html`
    <svg class="Goal-glyph" viewBox="0 0 1000 460">
      <g fill="currentColor" fill-rule="evenodd">
        <path d="M625.3 349C607 349 592 334.3 592 316c0-18.3 15-33.2 33.3-33.2 18.4 0 33.3 15 33.3 33.2 0 18.4-15 33.3-33.3 33.3m144.5-15.4c-12.6-17-94-120.6-208-120.6-98.8 0-204.7 89.7-204.7 89.7s-39.7-81.4-121-89.2H234c-1.8 0-3.4 1.7-3.2 3 .3 1.6 1.3 3 1.3 3 10.6 17.5 37.3 67 37.3 116.5 0 49.7-27 99.3-37.3 116.7l-.7 1c-.3.6-.5 1-.5 1.8 0 1.7 1.4 3 3 3.2h1c.2 0 .5 0 .8-.2C317.2 451 357 369.3 357 369.3S463 459 562 459c114 0 195.2-103.6 207.8-120.6.3-.4.6-.8 1-1V336v-1.2l-1-1.2M233.4 18.2l-.6-.2c-1.8 0-3.3 1.5-3.3 3.3V44c0 1 .4 1.8 1 2.4 55 47.8 107 3 119-9l1-.8c.5-.3 1-.6 1.8-.6s1.6.4 2.2 1h.2c62 59.4 127.3 11.3 140.8 0l1.4-1.2c.2-.2.7-.2 1-.2.6 0 1 0 1.4.2l1.3 1.2c13.6 11.3 78.7 59.4 141 0 .6-.6 1.4-1 2.2-1 .6 0 1.2.3 1.7.6.4.2.7.6 1 1 12 11.8 64 56.6 119 8.8.7-.6 1-1.4 1-2.4V21.3c0-1.8-1.4-3.3-3.2-3.3-.2 0-.4 0-.7.2l-1.6.8c-17 8.5-69.4 28.7-117-16.4l-1.5-1.3c-.4-.2-.8-.3-1.4-.3h-1c-.7.4-1.2 1-1.6 1.4-14.2 10.8-79.4 55-137.4.8l-.8-.8c-.7-.5-1.5-1-2.4-1-.8 0-1.6.5-2.2 1l-1 .8c-58 54-123 10-137.3-.8-.4-.5-1-1-1.6-1.3h-1c-.6 0-1 0-1.5.3L352 2.6c-47.5 45-100 25-117 16.4l-1.6-.8M349.6 120c.3-.3.5-.6 1-.8.4-.4 1-.6 1.7-.6.8 0 1.6.3 2.2 1h.2c62 59.2 127.3 11.2 140.8 0l1.4-1.3 1-.2c.6 0 1 .2 1.4.3l1.3 1.3c13.6 11.2 78.7 59.2 141 0 .6-.7 1.4-1 2.2-1 .6 0 1.2.2 1.7.6.4.2.7.5 1 1 12 11.7 64 56.5 119 8.7.7-.7 1-1.5 1-2.4v-22.8c0-1.8-1.4-3.2-3.2-3.2h-.7l-1.6 1c-17 8.4-69.5 28.5-117-16.5l-1.5-1.2c-.4-.2-.8-.3-1.4-.3-.3 0-.6 0-1 .2-.7.2-1.2.7-1.6 1.3-14.2 10.8-79.4 55-137.4.8l-1-1c-.5-.5-1.3-.8-2.2-.8-.8 0-1.6.3-2.2 1l-1 .8c-58 54-123 10-137.3-.8-.4-.6-1-1-1.6-1.3l-1-.2c-.6 0-1 0-1.5.3L352 85c-47.5 45-100 25-117 16.5l-1.6-.8h-.6c-1.8 0-3.3 1.3-3.3 3v23c0 .8.4 1.6 1 2.2 55 47.7 107 3 119-9"
        />
      </g>
    </svg>
  `
}
