module.exports={
  "SITE_TITLE": "VerdensmÃ¥l",
  "The 17 Goals": "De 17 VerdensmÃ¥l",
  "Goal %s": "MÃ¥l %s",
  "Oops": "Hov",
  "There is no page at this address. Try finding your way using the menu or from": "Siden blev ikke fundet. PrÃ¸v at finde siden ved at klikke i menuen ovenfor gÃ¥ til",
  "the homepage": "forsiden",
  "We apologize, an error has occured on our site. It may be temporary and you could": "Beklager, der opstod en fejl. Det kan vÃ¦re midlertidigt, og du kunne",
  "try again": "prÃ¸v igen,",
  "or go back to": "eller gÃ¥ tilbage til",
  "LOADING_TEXT_SHORT": "IndlÃ¦ser",
  "LOADING_TEXT_MEDIUM": "Indholdet indlÃ¦ses",
  "LOADING_TEXT_LONG": "Denne del af hjemmesiden hentes fra internettet. Hvis det tager for lang tid - sÃ¥ sÃ¸rg for at du har internetforbindelse.",
  "MONTH_0": "januar",
  "MONTH_1": "februar",
  "MONTH_2": "marts",
  "MONTH_3": "april",
  "MONTH_4": "maj",
  "MONTH_5": "juni",
  "MONTH_6": "juli",
  "MONTH_7": "august",
  "MONTH_8": "september",
  "MONTH_9": "oktober",
  "MONTH_10": "november",
  "MONTH_11": "december",
  "Published on %s %s, %s": "Udgivet den %s %s, %s",
  "Back to Goals": "Tilbage",
  "News": "Nyheder",
  "Nothing to see here": "Intet indhold",
  "Show more": "Vis mere",
  "Events": "Begivenheder",
  "%s logo in black and white": "%s logo i sort-hvid",
  "Visit website": "BesÃ¸g hjemmesiden",
  "Shortcuts": "Genveje",
  "Page does not exist": "Siden kunne ikke findes",
  "Download zip": "Hent zip-fil",
  "Denmark": "Danmark",
  "Greenland, Faroe Islands": "GrÃ¸nland, FÃ¦rÃ¸erne",
  "List": "Aktuelle",
  "Calendar": "Kalender",
  "Save event to calendar": "Importer i din kalender",
  "Spread the word": "Spred budskabet",
  "Share with others": "Del med andre",
  "E-mail to a friend": "E-mail til en du kender",
  "Check this out: %s": "Se her: %s",
  "Download document": "Hent dokument",
  "MONTH_NaN": "MONTH_NaN",
  "Your name": "Dit navn",
  "Your email": "Din email",
  "code and conspire": "code and conspire",
  "Sign up": "Tilmeld",
  "Opens in new tab": "Ãbner i ny fane",
  "Go to %s": "GÃ¥ til %s",
  "Past events": "Tidligere",
  "%s days": "%s dage",
  "Submit event": "Indsend",
  "Max 5mb": "Max 5mb",
  "By %s": "Af %s",
  "Yesterday": "I gÃ¥r",
  "Today": "I dag",
  "%s/%s words": "%s/%s ord",
  "Submit": "Submit",
  "LOADING_TEXT_SMALL": "LOADING_TEXT_SMALL",
  "Indsend": "Indsend",
  "Read also": "LÃ¦s ogsÃ¥",
  "Vi tager ansvar for indholdet og er tilmeldt": "Vi tager ansvar for indholdet og er tilmeldt",
  "Udvikling og digital design": "Udvikling og digital design",
  "Something seems to be missing. Please make sure that you have filled in all requried fields (marked with a *).": "Der mangler noget information. Tjek om du har udfyldt alle obligatoriske felter (markeret med *).",
  "Read article": "LÃ¦s hele artiklen",
  "See all news": "Se alle nyheder",
  "Go to event": "GÃ¥ til event",
  "Most read this month": "Mest lÃ¦st denne mÃ¥ned",
  "Read more": "LÃ¦s mere",
  "Most read": "Mest lÃ¦ste",
  "Verdenstimen": "Verdenstimen",
  "Material": "Material",
  "Materials": "Materialer",
  "Go to material": "Download materiale",
  "Choose educational level": "VÃ¦lg mellem uddannelsestrin",
  "All educational levels": "Alle uddannelsestrin",
  "Find your teaching material": "Find dit undervisningsmateriale",
  "Choose school subject": "VÃ¦lg mellem skolefag",
  "Navigation": "Navigation",
  "Choose goal": "VÃ¦lg mÃ¥l",
  "Subjects": "Fag",
  "Duration": "Tidsforbrug",
  "Produced by": "Produceret af",
  "Audience": "MÃ¥lgruppe",
  "The goals": "VerdensmÃ¥l",
  "More educational material": "Flere undervisningsmaterialer",
  "Materialer": "Materialer",
  "All school subjects": "Alle fag",
  "All goals": "Alle mÃ¥l",
  "On this page you can find all materials suitable for %s. Use the filters bellow to narrow the search to specific goals or subjects.": "PÃ¥ denne side kan du finde alle materialer der passer til %s. Tilpas sÃ¸gekriterier for at indsnÃ¦vre sÃ¸gningen til specifikke mÃ¥l eller fag.",
  "No materials found": "Ingen materialer fundet",
  "Show all subjects": "Se alle skolefag",
  "Latest materials": "Seneste materialer",
  "Browse materials": "Flere materialer",
  "Goal": "MÃ¥l",
  "and %s more": "og %s til",
  "Explore materials": "GÃ¥ pÃ¥ opdagelse I forlÃ¸b",
  "Latest news": "Seneste nyheder",
  "All subjects": "Alle skolefag",
  "Danish goals": "Danish goals",
  "ButtonText": "ButtonText",
  "Explore": "Explore",
  "Foo": "Foo",
  "Bar": "Bar",
  "Filtrer": "Filtrer",
  "%s": "%s",
  "VÃ¦lg tags": "VÃ¦lg tags",
  "Alle tags": "Alle tags",
  "Tags": "Tags",
  "Ryd": "Ryd",
  "VÃ¦lg region": "VÃ¦lg region"
}