var html = require('choo/html')

module.exports = function () {
  return html`
    <svg class="Goal-glyph" viewBox="0 0 1000 511">
      <g fill="currentColor" fill-rule="evenodd">
        <path d="M500.4 332c-42 0-76.2-34-76.2-76 0-34.3 22.5-63.2 53.5-73v28.5c-16 8.2-27.2 25-27.2 44.4 0 27.5 22.3 49.8 50 49.8 27.5 0 49.8-22.3 49.8-50 0-19.3-11.2-36-27.4-44.4V183c31 9.7 53.6 38.7 53.6 73 0 42-34 76-76.2 76zm-11-163.6c0-5 5-10.3 11-10.3s11 5.5 11 10.4v50.3c0 5-5 10.2-11 10.2s-11-5.4-11-10.3v-50.3zm11-58c-81 0-146.6 65.7-146.6 146.6 0 81 65.6 146.6 146.6 146.6S647 338 647 257s-65.7-146.5-146.6-146.5zM332 255.8c0-6.7-5.8-12-11-12h-64.3c-5.4 0-11.4 5.3-11.4 12 0 6.6 6 12 11.4 12H321c5.2 0 11-5.4 11-12M755.5 255.8c0-6.7-6-12-11.3-12H680c-5.4 0-11.3 5.3-11.3 12 0 6.6 6 12 11.3 12h64.2c5.4 0 11.3-5.4 11.3-12M500.4 87.5c6.7 0 12-6 12-11.2V12c0-5.3-5.3-11.3-12-11.3-6.6 0-12 6-12 11.4v64.3c0 5.3 5.4 11.2 12 11.2M500.4 510.8c6.7 0 12-5.8 12-11.2v-64.2c0-5.4-5.3-11.3-12-11.3-6.6 0-12 6-12 11.4v64.2c0 5.4 5.4 11.2 12 11.2M354.4 172.2c3.3-5.8.8-13.4-3.8-16L295 124c-4.8-2.6-12.7-1-16 5-3.3 5.6-.8 13.3 4 16l55.6 32c4.7 2.5 12.5.8 15.8-5M721.8 382.5c3.3-5.8.8-13.4-3.8-16l-55.7-32c-4.7-2.6-12.5-1-15.8 5-3.3 5.6-1 13.3 3.8 16l55.7 31.8c4.7 2.7 12.5 1 15.8-4.8M584 109.8c5.8 3.2 13.4.8 16-3.8l32-55.8c2.6-4.7 1-12.5-5-15.8-5.7-3.3-13.3-.8-16 4L579 94c-2.6 4.6-.8 12.4 5 15.8M373.7 477.2c5.8 3.3 13.4.8 16-3.8l32-55.7c2.6-4.7 1-12.6-5-16-5.6-3.2-13.3-.7-16 4l-32 55.7c-2.5 4.7-.8 12.5 5 15.8M416.5 110c5.8-3.4 7.5-11.2 4.8-16l-32-55.5c-2.7-4.7-10.3-7.2-16-3.8-5.8 3.3-7.5 11-4.8 15.8l32 55.6c2.6 4.8 10.3 7.2 16 4M627.6 477c5.7-3.4 7.5-11.2 4.8-16l-32-55.5c-2.7-4.7-10.3-7.2-16-4-6 3.5-7.6 11.3-5 16l32 55.6c2.8 4.8 10.4 7.2 16.2 4M646.3 172c3.3 5.7 11 7.4 15.7 4.7l55.7-32c4.7-2.7 7-10.3 3.8-16-3.3-5.8-11-7.6-15.8-5l-55.7 32c-4.6 2.8-7 10.4-3.7 16.2M279.3 383c3.3 5.7 11 7.4 15.8 4.8l55.7-32c4.7-2.7 7.2-10.4 3.8-16-3.3-6-11-7.6-15.8-5L283 367c-4.6 2.7-7 10.4-3.7 16.2"
        />
      </g>
    </svg>
  `
}
