var html = require('choo/html')

module.exports = function () {
  return html`
    <svg class="Goal-glyph" viewBox="0 0 1000 470">
      <g fill="currentColor" fill-rule="evenodd">
        <path d="M836 249c0-28.2-18.2-51-45.5-51-13.7 0-30.2 15-39 24.2-9-9.2-24.5-24.2-38.2-24.2-27.3 0-46.5 22.8-46.5 51 0 14 5.6 26.8 14.5 36l70 70.2 70-70.2c9-9.2 14.6-22 14.6-36"/>
        <path d="M537.4 470c-.2 0-.4 0-.7-.2-5.3-.3-9.7-4.2-10.6-9.5L490.6 253l-54 100c-2.3 4.3-7.3 6.7-12 5.8-5-1-8.8-4.8-9.5-9.7l-23-160-41.8 94c-1.8 4.3-6 7-10.5 7H177c-6.5 0-11.7-5.2-11.7-11.6 0-6.3 5.2-11.5 11.6-11.5H332l55-123.8c2.2-4.7 7-7.4 12.2-6.7 5 .7 9 4.7 9.8 9.7l23.6 165 53.2-98.6c2.4-4.3 7.2-6.7 12-6 5 1 8.7 4.7 9.5 9.6l33 192L660.7 9c1.4-4.5 5.4-7.8 10-8.2 5-.3 9.3 2.3 11.4 6.5l63.3 131c2.8 5.8.4 12.7-5.3 15.5-5.7 2.7-12.6.3-15.4-5.4L674.2 44 548.4 461.7c-1.4 5-6 8.2-11 8.2"/>
      </g>
    </svg>
  `
}
